import {trpc} from '@/api/trpcClient';
import {useLocale} from '@/hooks/useLocale';
import {CurrencyCode, DEFAULT_CURRENCY} from '@zentact/common';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {createContainer} from 'unstated-next';

const Store = createContainer(() => {
  const {localeState, locale} = useLocale();
  const navigate = useNavigate();
  const [currency, setCurrency] = useState(DEFAULT_CURRENCY);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [pspMerchantAccountName, setPspMerchantAccountName] = useState<string | undefined>(
    undefined
  );
  const [paymentMethods, setPaymentMethods] = useState<string[] | undefined>(undefined);
  const tenant = trpc.tenant.getContextTenant.useQuery(undefined, {enabled: !!accessToken});
  const organizationList = trpc.organization.getActiveOrganizations.useQuery(
    // biome-ignore lint/style/noNonNullAssertion: Checked in the enabled prop
    {pspMerchantAccountName: pspMerchantAccountName!},
    {
      enabled: !!accessToken && !!pspMerchantAccountName,
      initialData: [],
    }
  );
  const orgsWithBoardedMerchants = useMemo(() => {
    return organizationList.data.filter(o => o.hasBoardedMerchants);
  }, [organizationList.data]);
  const merchantAccounts = trpc.merchantAccount.getAllMerchantAccounts.useQuery(
    // biome-ignore lint/style/noNonNullAssertion: Checked in the enabled prop
    {pspMerchantAccountName: pspMerchantAccountName!},
    {
      enabled: !!accessToken && !!pspMerchantAccountName,
      refetchOnWindowFocus: true,
      initialData: [],
    }
  );

  const activeAndInactiveMerchantAccounts = useMemo(() => {
    return merchantAccounts.data.filter(
      merchant => merchant.status === 'ACTIVE' || merchant.status === 'INACTIVE'
    );
  }, [merchantAccounts.data]);

  useEffect(() => {
    if (!tenant.data) return;

    // Merchant account is persisted between sessions
    let merchantAccount = tenant.data.pspMerchantAccounts.find(
      a => a.pspMerchantAccountName === localStorage.getItem('pspMerchantAccountName')
    );

    // Or using default merchant account
    if (!merchantAccount) {
      merchantAccount = tenant.data.pspMerchantAccounts.find(
        a => a.pspMerchantAccountName === tenant.data.defaultPspMerchantAccount
      );
    }

    if (!merchantAccount) return;

    setPspMerchantAccountName(merchantAccount.pspMerchantAccountName);
    setPaymentMethods(merchantAccount.paymentMethods);
    setCurrency(merchantAccount.currency as CurrencyCode);
  }, [tenant.data]);

  const setPspMerchantAccountWithCaching = useCallback(
    (pspMerchantAccountName: string) => {
      const merchantAccount = tenant.data?.pspMerchantAccounts.find(
        a => a.pspMerchantAccountName === pspMerchantAccountName
      );

      if (!merchantAccount) return;

      setPspMerchantAccountName(merchantAccount.pspMerchantAccountName);
      setPaymentMethods(merchantAccount.paymentMethods);
      setCurrency(merchantAccount.currency as CurrencyCode);
      localStorage.setItem('pspMerchantAccountName', pspMerchantAccountName);

      // clear the query string if present
      if (new URL(window.location.href).search) {
        navigate({pathname: window.location.pathname}, {replace: true});
      }
    },
    [tenant.data]
  );

  return {
    isLoading: localeState.isLoading || tenant.isLoading || organizationList.isLoading,
    isError: localeState.isError || tenant.isError || organizationList.isError,
    tenant: {...tenant.data},
    tenantRefetch: tenant.refetch,
    organizationList: organizationList.data,
    merchantAccounts: merchantAccounts.data,
    activeAndInactiveMerchantAccounts,
    orgsWithBoardedMerchants,
    refetchOrganizationList: organizationList.refetch,
    locale,
    currency,
    setAccessToken,
    pspMerchantAccountName,
    setPspMerchantAccountName: setPspMerchantAccountWithCaching,
    paymentMethods,
  };
});

export const useStore = () => {
  return Store.useContainer();
};

export const StoreProvider = Store.Provider;
