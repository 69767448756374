import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {TerminalsItem} from '@zentact/api/src/trpc/routers/terminalRouter';
import {
  Breadcrumbs,
  Button,
  EntityPicker,
  ResetTableFiltersButton,
  Typography,
  useNotification,
  useToggleWithData,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useState} from 'react';
import {z} from 'zod';
import {exportToCsvTerminals} from './csv-export';
import {TerminalsManageTable} from './terminals-manage/manage-table';
import {ManageViewPanel} from './terminals-manage/manage-view-panel';

const getBreadCrumbs = () => [
  {name: t`Terminals`, href: RoutePath.TERMINALS, current: false},
  {name: t`Manage Terminals`, href: '#', current: true},
];

const searchParamsSchema = z.object({
  selectedMerchant: z.string().optional(),
  selectedOrganization: z.string().optional(),
});

export const TerminalsManage = () => {
  const {orgsWithBoardedMerchants: organizationList, pspMerchantAccountName} = useStore();
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(searchParamsSchema);

  const selectedMerchant = typedSearchParams?.selectedMerchant;
  const selectedOrganization = typedSearchParams?.selectedOrganization;

  const merchantList =
    trpc.merchantAccount.getMerchantAccountsList
      .useQuery(
        {
          // biome-ignore lint/style/noNonNullAssertion: Checked in the enabled prop
          where: {pspMerchantAccountName: pspMerchantAccountName!},
        },
        {enabled: !!pspMerchantAccountName}
      )
      .data?.rows.reduce<{name: string; id: string}[]>((acc, {merchantAccount}) => {
        if (merchantAccount?.storeId && merchantAccount.businessName) {
          acc.push({name: merchantAccount.businessName, id: merchantAccount.storeId});
        }
        return acc;
      }, []) || [];

  const terminalsList = trpc.terminal.terminals.useQuery({
    ...pagination,
    storeId: selectedMerchant,
    organizationId: selectedOrganization,
    pspMerchantAccountName,
  });

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [selectedMerchant, selectedOrganization]);

  const {
    data: terminalRow,
    on: openViewTerminalPanel,
    off: closeViewTerminalPanel,
  } = useToggleWithData<TerminalsItem | null>();

  const trpcContext = trpc.useUtils();

  const [isCsvLoading, setCsvLoading] = useState(false);
  const handleCsvExport = useCallback(async () => {
    setCsvLoading(true);
    try {
      const fullTerminalsList = await trpcContext.terminal.terminals.fetch({
        storeId: selectedMerchant,
        organizationId: selectedOrganization,
        pspMerchantAccountName,
      });
      exportToCsvTerminals(fullTerminalsList);
      showSuccessNotification(t`Payments .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Payments .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [setCsvLoading, trpcContext, selectedMerchant, selectedOrganization, terminalsList.data]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex justify-between gap-2 pt-4 max-2xl:flex-wrap 2xl:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Manage Terminals</Trans>
        </Typography>
        <div className="flex gap-2 font-normal max-2xl:flex-wrap 2xl:items-center">
          <div className="max-sm:w-full">
            <ResetTableFiltersButton
              defaultFilters={{}}
              activeFilters={typedSearchParams}
              setFilters={setTypedSearchParams}
            />
          </div>
          <div className="font-normal shrink-0 max-sm:w-full">
            <EntityPicker
              options={merchantList}
              label={t`All Merchants`}
              selected={selectedMerchant}
              onChange={value => setTypedSearchParams({selectedMerchant: value})}
            />
          </div>
          <div className="font-normal shrink-0 max-sm:w-full">
            <EntityPicker
              options={organizationList}
              selected={selectedOrganization}
              onChange={value => setTypedSearchParams({selectedOrganization: value})}
            />
          </div>
          <Button
            type="button"
            variant="primary"
            size="md"
            className="w-fit max-sm:w-full"
            isLoading={isCsvLoading}
            onClick={handleCsvExport}
            disabled={!terminalsList.data || terminalsList.data.rows.length === 0}
          >
            <Trans>Export to CSV</Trans>
          </Button>
        </div>
      </div>
      <div className="mt-4 overflow-x-auto">
        <TerminalsManageTable
          pagination={pagination}
          refetch={terminalsList.refetch}
          terminalsList={terminalsList.data}
          onPaginationChange={setPagination}
          openViewTerminalPanel={openViewTerminalPanel}
          isLoading={
            terminalsList.isLoading || (terminalsList.isRefetching && terminalsList.isPreviousData)
          }
        />
      </div>
      <ManageViewPanel
        isOpen={!!terminalRow}
        onCancel={closeViewTerminalPanel}
        terminalsRow={terminalRow}
      />
    </div>
  );
};
