import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {
  InvitedOrganizationUserItem,
  InvitedTenantUserItem,
} from '@zentact/api/src/trpc/routers/usersRouter';
import {
  Breadcrumbs,
  Button,
  EditInvitedUserPanel,
  InviteUser,
  InvitedUsersList,
  TableSortValue,
  Typography,
  useDrawerState,
  useToggleWithData,
} from '@zentact/ui-tailwind';
import {useState} from 'react';

const breadcrumbs = () => [
  {name: t`Users`, href: RoutePath.USERS, current: false},
  {name: t`Invited Users`, href: RoutePath.INVITED_USERS, current: true},
];

export const InvitedUsers = () => {
  const [filters, setFilters] = useState<{
    status: [];
  }>({status: []});
  const [sort, setSort] = useState<TableSortValue<string> | null>(null);
  const [isInviting, setIsInviting] = useState(false);
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});
  const {tenant} = useStore();

  const invitedTenantUsersList = trpc.users.getInvitedTenantUsers.useQuery(
    {
      ...pagination,
      ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );

  const {
    isOpen: isInviteUserFormOpen,
    open: openInviteUserForm,
    close: closeInviteUserForm,
    onSuccess: onInviteUserSuccess,
  } = useDrawerState({
    onSuccessHandler: () => {
      setIsInviting(true);
      setTimeout(
        () =>
          invitedTenantUsersList.refetch().then(() => {
            setIsInviting(false);
          }),
        2000
      );
    },
  });

  const {
    data: editUserRow,
    on: openEditInvitedUserPanel,
    off: closeEditInvitedUserPanel,
  } = useToggleWithData<InvitedOrganizationUserItem | InvitedTenantUserItem>();

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={breadcrumbs()} />
      <div className="flex pt-4">
        <Typography variant="header-page" className="flex-1">
          <Trans>Invited Users</Trans>
        </Typography>
        <div className="flex gap-2 font-normal max-sm:flex-wrap sm:items-center">
          <Button
            type="button"
            variant="primary"
            size="md"
            className="w-fit"
            onClick={openInviteUserForm}
          >
            <Trans>Invite</Trans>
          </Button>
        </div>
      </div>
      <div className="mt-4 overflow-x-auto">
        <InvitedUsersList
          usersList={invitedTenantUsersList.data}
          invitedUsersRefetch={invitedTenantUsersList.refetch}
          openInvitedUserEditPanel={openEditInvitedUserPanel}
          filters={filters}
          setFilters={setFilters}
          sort={sort}
          setSort={setSort}
          noDataMessage={
            <div>
              <h3 className="text-sm font-semibold text-gray-900">
                <Trans>No Pending Invitations</Trans>
              </h3>
            </div>
          }
          isLoading={
            isInviting ||
            invitedTenantUsersList.isLoading ||
            (invitedTenantUsersList.isRefetching && invitedTenantUsersList.isPreviousData)
          }
          isFetching={invitedTenantUsersList.isFetching}
          trpc={trpc}
          pagination={pagination}
          onPaginationChange={setPagination}
          mode="tenantUser"
        />
      </div>
      <InviteUser
        isOpen={isInviteUserFormOpen}
        onCancel={closeInviteUserForm}
        onSuccess={onInviteUserSuccess}
        organizationOrTenantName={tenant?.name}
        trpc={trpc}
        mode="tenantUser"
      />
      {!!editUserRow && (
        <EditInvitedUserPanel
          userRow={editUserRow}
          onClose={closeEditInvitedUserPanel}
          onSuccess={invitedTenantUsersList.refetch}
          trpc={trpc}
          mode="tenantUser"
        />
      )}
    </div>
  );
};
