import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {Button, InputText, Label, ValidationError} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import z from 'zod';

const notificationEmailsLimit = 10;

const notificationEmailsFormSchema = () =>
  z
    .object({
      notificationEmails: z.array(
        z
          .string()
          .max(50, t`Email address must not exceed 50 characters`)
          .refine(val => val === '' || z.string().email().safeParse(val).success, {
            message: t`Please enter a valid email address`,
          })
      ),
    })
    .superRefine((data, ctx) => {
      const emails = data.notificationEmails;
      const emailCounts = emails.reduce<Record<string, number[]>>((acc, email, index) => {
        if (email.trim() !== '') {
          acc[email] = (acc[email] || []).concat(index);
        }
        return acc;
      }, {});

      for (const [_email, indexes] of Object.entries(emailCounts)) {
        if (indexes.length > 1) {
          for (const index of indexes.slice(1)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['notificationEmails', index],
              message: t`This email address is duplicated. Each email must be unique.`,
            });
          }
        }
      }
    });

export type NotificationEmailsFormData = z.infer<ReturnType<typeof notificationEmailsFormSchema>>;

type Props = {
  defaultValues: {
    notificationEmails: string[];
  };
  onSave: (formData: NotificationEmailsFormData) => Promise<void>;
};

export const NotificationEmailsForm = ({defaultValues, onSave}: Props) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
    watch,
    setValue,
    clearErrors,
  } = useForm<NotificationEmailsFormData>({
    resolver: zodResolver(notificationEmailsFormSchema()),
    defaultValues: {
      notificationEmails: defaultValues.notificationEmails.length
        ? defaultValues.notificationEmails
        : [''],
    },
  });

  const notificationEmails = watch('notificationEmails') || [''];

  useEffect(() => {
    reset({
      notificationEmails: defaultValues.notificationEmails.length
        ? defaultValues.notificationEmails
        : [''],
    });
  }, [defaultValues.notificationEmails]);

  const onAddNewNotificationEmail = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      if (notificationEmails.length < notificationEmailsLimit) {
        setValue('notificationEmails', [...notificationEmails, '']);
      }
    },
    [notificationEmails, setValue]
  );

  const onRemoveNotificationEmail = useCallback(
    (index: number) => {
      const newNotificationEmails = [
        ...notificationEmails.slice(0, index),
        ...notificationEmails.slice(index + 1),
      ];
      setValue('notificationEmails', newNotificationEmails);
      clearErrors(`notificationEmails.${index}`);
    },
    [notificationEmails, setValue]
  );

  const submitForm = async (formData: NotificationEmailsFormData) => {
    setLoading(true);
    const filteredNotificationEmails = formData.notificationEmails.filter(email => email);
    await onSave({notificationEmails: filteredNotificationEmails});
    setLoading(false);
  };

  return (
    <form className="flex flex-col gap-5">
      <>
        {notificationEmails.map((_email, index) => (
          <div className="flex flex-col w-full gap-6 md:w-1/2">
            <div key={`notificationEmail-${index}`} className="flex items-center gap-2 mt-3">
              <div className="flex flex-col w-full md:flex-row">
                <Label className="w-full" text={t`Notification Email ${index + 1}`}>
                  <div className="grid grid-cols-4 gap-4">
                    <InputText
                      className="w-full col-span-3"
                      type="email"
                      {...register(`notificationEmails.${index}` as const)}
                      hasError={Boolean(errors.notificationEmails?.[index])}
                      placeholder={t`example@mail.com`}
                    />
                    {index > 0 && (
                      <Button
                        type="button"
                        onClick={() => onRemoveNotificationEmail(index)}
                        className="bg-red-600 w-fit hover:bg-red-500 focus:ring-red-500"
                      >
                        <Trans>Remove</Trans>
                      </Button>
                    )}
                  </div>
                  <ValidationError isVisible={Boolean(errors.notificationEmails?.[index])}>
                    {errors.notificationEmails?.[index]?.message}
                  </ValidationError>
                </Label>
              </div>
            </div>
          </div>
        ))}
        <div className="flex flex-col justify-between w-full mt-5 md:flex-row">
          <div className="flex mb-2 sm:w-auto">
            {notificationEmails.length < notificationEmailsLimit && (
              <Button
                size="xl"
                className="inline-block w-1/2 md:w-auto"
                onClick={e => onAddNewNotificationEmail(e)}
              >
                {t`Add new`}
              </Button>
            )}
          </div>
          <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
            <Button
              type="button"
              className="inline-block w-1/2 md:w-auto"
              size="xl"
              onClick={handleSubmit(submitForm)}
              isLoading={loading}
              disabled={loading}
            >
              <Trans>Save notification emails</Trans>
            </Button>
          </div>
        </div>
      </>
    </form>
  );
};
