import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {Trans, t} from '@lingui/macro';
import {Breadcrumbs, Typography, useNotification} from '@zentact/ui-tailwind';
import {NotificationEmailsForm, NotificationEmailsFormData} from './notification-email-form';

const getBreadCrumbs = () => [
  {name: t`Configure`, href: RoutePath.CONFIGURE, current: false, disabled: true},
  {name: t`Notifications`, href: RoutePath.NOTIFICATION_EMAILS, current: true},
];

export const NotificationEmails = () => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const trpcContext = trpc.useUtils();
  const {data: tenantBrandData} = trpc.tenant.getContextTenant.useQuery();
  const {notificationEmails} = tenantBrandData?.brandConfiguration || {};

  const updateBranding = trpc.tenant.updateBranding.useMutation({
    onSuccess: updatedTenant => {
      trpcContext.tenant.getContextTenant.setData(undefined, updatedTenant);
    },
    onError: err => {
      console.error(err);
    },
  });

  const onUpdateNotificationEmails = async (data: NotificationEmailsFormData) => {
    return new Promise<void>(resolve => {
      updateBranding.mutate(data, {
        onSuccess: () => {
          showSuccessNotification(t`Notification email was successfully updated`);
          resolve();
        },
        onError: () => {
          showErrorNotification(t`Failed to update notification email`);
          resolve();
        },
      });
    });
  };

  return (
    <div>
      <Breadcrumbs pages={getBreadCrumbs()} />
      <Typography variant="header-page" className="pt-4">
        <Trans>Notifications</Trans>
      </Typography>
      <div className="mt-4 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow">
        <div className="px-4 py-5 sm:px-6">
          <Typography variant="header-section">
            <Trans>Incoming Email Address</Trans>
          </Typography>
          <div className="mt-3 text-gray-500">
            <Trans>
              Choose one or more email addresses to receive incoming emails to your account. This
              can include individual or group email addresses for you or your team.
            </Trans>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <NotificationEmailsForm
            defaultValues={{
              notificationEmails: notificationEmails || [''],
            }}
            onSave={onUpdateNotificationEmails}
          />
        </div>
      </div>
    </div>
  );
};
