export enum SplitConfigurationGroupPublicStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum SplitConfigurationPaymentMethod {
  ANY = 'ANY', // All cards
  ACH = 'ach',
  INTERAC = 'interac',
  AMEX = 'amex',
  PAYBYBANK = 'paybybank',
}
